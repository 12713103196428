<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-row>
        <b-col lg="6">
          <b-card class="text-center" :title="'Um total de ' + docs.length + ' usuários realizaram transações no período.'">
            <!-- <b-row>
              <b-col md="2">
                <b-form-radio
                  v-model="transactionStatus"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="0"
                >
                  Pendente
                </b-form-radio>
              </b-col>
              <b-col md="2">
                <b-form-radio
                  v-model="transactionStatus"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="1"
                >
                  Pago
                </b-form-radio>
              </b-col>
              <b-col md="2">
                <b-form-radio
                  v-model="transactionStatus"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="2"
                >
                  Cancelado
                </b-form-radio>
              </b-col>
              <b-col md="2">
                <b-form-radio
                  v-model="transactionStatus"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="3"
                >
                  Processando
                </b-form-radio>
              </b-col>
            </b-row> -->
            
            <b-row class="text-center mt-1">
              <b-col>
                <p>Depositos</p>
                <span class="macro">{{ totalDep | brl }}</span>
              </b-col>
              <b-col>
                <p>Saques</p>
                <span class="macro">{{ totalWth | brl }}</span>
              </b-col>
              <b-col>
                <p>Resultado</p>
                <span class="macro">{{ totalDep-totalWth | brl }}</span>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

         <b-col>
          <b-card title="Data inicial">
            <template>
             <b-form-datepicker
              v-model="from"
              locale="pt-br"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
             />
            </template>
          </b-card>
        </b-col>
        <b-col>
          <b-card title="Data final">
            <template>
              <b-form-datepicker
                v-model="to"
                locale="pt-br"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
              />
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="docs">
        <!-- <b-row class="w-100 mb-2">
            <b-col>Nome</b-col>
            <b-col>Depositos</b-col>
            <b-col>Saques</b-col>
            <b-col>Resultado</b-col>
          </b-row>
        <div v-for="transaction, idx in transactionsObj" class="w-100">
          <b-row>
            <b-col>{{ docs.filter(d=>d.user_id == idx)[0].value }}</b-col>
            <b-col>{{ transaction.deposits  | brl}}</b-col>
            <b-col>{{ transaction.withdrawals  | brl}}</b-col>
            <b-col>{{ transaction.deposits - transaction.withdrawals | brl}}</b-col>
          </b-row>
        </div>
         -->
        <b-table striped 
        :fields="fields"
        hover :items="tableItems"></b-table>


      </b-row>
      <!-- <withdraws-list
        :orders="withdraw_orders.data"
        :context-actions="'admin'"
        @emitSidebar="openTransactionSidebar"
      /> -->
    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
    <edit-transaction-sidebar
      ref="transactionSidebar"
      :transaction="transactionObj"
      @refreshOrders="refreshOrders()"
    />

  </div>

</template>

<script>
import Datepicker from 'vuejs-datepicker'

import {
  BCard, BCol, BRow, BCardText, BLink, BFormRadio,BFormDatepicker, BTable
} from 'bootstrap-vue'
import GeneralGroupsList from '@/components/groups/GeneralGroupsList.vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'
import WithdrawsList from '@/components/transactions/WithdrawsList.vue'
import EditTransactionSidebar from '@/components/transactions/EditTransactionSidebar.vue'

export default {
  components: {
    BTable,
    BCard,
    BFormDatepicker,
    BCardText,
    BLink,
    GeneralGroupsList,
    underscore: _,
    LogoLoaderAnimation,
    WithdrawsList,
    EditTransactionSidebar,
    BFormRadio,
    BCol,
    BRow,

  },
  data() {
    return {
      from: new Date(new Date().getTime() - (3 * 60 * 60 * 1000)).toISOString().split('T')[0],
      to: new Date(new Date().getTime() + (21 * 60 * 60 * 1000)).toISOString().split('T')[0],
      searchQuery: '',
      currentPage: 1,
      tableItems: [],
      transactionObj: null,
      transactionStatus: 0,
      fields: [
          { key: 'Nome', sortable: true },
          { key: 'Depositos', sortable: true },
          { key: 'Saques', sortable: true },
          { key: 'Resultado', sortable: true },
        ],
      statusName: ['PENDING', 'CONFIRMED', 'CANCELED','PROCESSING'],
      transactions:null,
      transactionsObj:null,
      usersToName:[],
      docs:null,
      totalDep:0,
      totalWth:0
    }
  },
  apollo: {
    transactions: {
      query: gql`query($status:String, $from: Mixed, $to: Mixed){
      transactions: 
        orders(first:1000000,status:$status , where: {column:CREATED_AT, operator:GTE, value: $from , AND:{column:CREATED_AT, operator:LTE, value: $to}}){
          data{
            id,
            order_type,
            user_id,
            amount,
            status,
          },
        }
      }`,
      client: 'cashioClient',
      variables() {
        return {
          status: 'CONFIRMED',
          from: this.from+ ' 03:00:00',
          to: this.to+ ' 03:00:00',
        }
      },
    },
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  created() {
    // this.searchUser()
  },
  watch:{
    transactions:function(){
      this.totalDep = 0;
      this.totalWth = 0;
      this.transactionsObj = this.transactions.data.reduce((acc, transaction) => {
        const { user_id, order_type, amount } = transaction;
        if (!acc[user_id]) {
          acc[user_id] = { deposits: 0, withdrawals: 0 };
        }
        if (order_type === "DEPOSIT") {
          acc[user_id].deposits += amount;
          this.totalDep += amount;
        } else if (order_type === "WITHDRAW") {
          acc[user_id].withdrawals += amount;
          this.totalWth += amount;
        }
        return acc;
      }, {});
      this.usersToName = Object.keys(this.transactionsObj)
    },
    usersToName:function(){
      this.tableItems = []; // Clear the array before populating with new data
      console.log(this.usersToName)
        this.$apollo.query({
            query: gql`query($ids:[ID!]){
              get_users_doc(user_ids:$ids,type:"FULLNAME"){
                user_id
                value
              }
            }`,
            variables:{
              ids: this.usersToName,
            },
            client: 'identityClient',
          }).then((data) => {
              this.docs = data.data.get_users_doc;
              Object.keys(this.transactionsObj).forEach(userId => {
                let name = this.docs.filter(d=>d.user_id == userId)[0].value;
                this.tableItems.push({ "Nome": name, "Depositos": (this.transactionsObj[userId].deposits).toFixed(2), "Saques": (this.transactionsObj[userId].withdrawals).toFixed(2), "Resultado" :  (this.transactionsObj[userId].deposits - this.transactionsObj[userId].withdrawals).toFixed(2)});
              });
              
          }).catch((error) => {
            console.error(error)
          })
    }
  },
  methods: {
    openTransactionSidebar(e) {
      this.transactionObj = e
      this.$refs.transactionSidebar.toogleV()
    },
  },
}
</script>

<style>

</style>
